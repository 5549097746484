<template>
  <royalty-incentive
    v-model="kind"
    :title="title"
  />
</template>

<script>
export default {
  components: {
    RoyaltyIncentive: () => import(/* webpackChunkName: "royaltyIncentive" */ '@/components/RoyaltyIncentive'),
  },
  data () {
    return {
      kind: 'monthly',
      title: 'Monthly',
    }
  },
}
</script>
